import React from 'react';

import Setup from './boot/Setup';

function App() {
  return (
    <Setup />
  );
}

export default App;
