import styled from 'styled-components';
export const SideBar = styled.i`
    display: inline-block;
    vertical-align: top;
    @media (max-width: 740px){
        position: fixed;
        z-index: 11;
    }
`;

export const MenuIcon = styled.i`
    cursor: pointer;
    display: none;
    font-size: 28px;
    padding: 8px 16px;
    @media (max-width: 740px){
        display: inline-block;
        width: 100%;
    }
    &.hide{
        display: none !important;
    }
    z-index: 3;
`;

export const Container = styled.div`
    background: #F7F9FA;
    display: inline-block;
    height: 100vh;
    padding-left: 40px;
    vertical-align: top;
    width: 240px;
    @media (max-width: 740px){
        display: none;
    }
    &.show{
        display: inline-block !important;
    }
`;

export const BlackOut = styled.div`
    background: rgba(0, 0, 0, 0.44);
    cursor: pointer;
    display: inline-block;
    height: 100vh;
    width: calc(100% - 240px);
    vertical-align: top;
    position: fixed;
    z-index: 10;
    &.show{
        display: inline-block !important;
    }
    &.hide{
        display: none !important;
    }
    @media (max-width: 740px){
        display: none !important;
    }
`;

export const ImgLogo = styled.img`
    height: 32px;
    margin: 38px 0;
    width: 32px;
`;

export const Item = styled.div`
    cursor: pointer;
    font-size : 16px;
    height: 40px;
    width: 100%;
    & a{
        color: inherit;
        font-style: normal;
        text-decoration: none;
    }
`;