import React from 'react';
import styled from 'styled-components';

import source from '../../res/img/loading.gif';

const LoadingScreen = ({ color = "rgba(255, 255, 255, 0.44)"})=>{
    return (
        <Container Color={color}>
            <img alt="loading" src={source} />
        </Container>
    )
}

const Container = styled.div`
    align-items: center;
    background: ${props => props.Color};
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    & img{
        display: inline-block;
    }
`;

export default LoadingScreen;