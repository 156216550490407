import React from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
    SideBar,
    MenuIcon,
    Container,
    ImgLogo,
    Item,
    BlackOut} from './Styles';


import Logo from '../../res/img/logo192.png';

const SideBarClass = ({profile, match}) => {
    //const { path } = match;
    
    const [showMenu, setMenuStatus] = React.useState(false);
    const handleShowMenu = ()=>{
        setMenuStatus(!showMenu);
    }
    return (
        <SideBar>
            <MenuIcon onClick={handleShowMenu} className={"fas fa-bars" + (showMenu ? ' hide' : '')} />
            <Container className={(showMenu ? 'show' : '')}>
                <ImgLogo src={Logo} alt="logo" />
                {(profile === 'superadmin' || profile === 'admin') && (
                    <Item>
                        <Link to="/companies">
                            Compañías
                        </Link>
                    </Item>
                )}
                <Item>
                    <Link to="/users">
                        Usuarios
                    </Link>
                </Item>
                <Item>
                    <Link to="/downloads">
                        Descargas
                    </Link>
                </Item>
            </Container>
            <BlackOut onClick={handleShowMenu}  className={(showMenu ? 'show' : 'hide')}/>
        </SideBar>
    )
}


export default withRouter(SideBarClass);