import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function GuestRoute({
    component: Component,
    authenticated,
    ...rest
}) {
    return (
        <Route
            {...rest}
            render={props =>
                (authenticated === true && (props.noRedirect)) ? (
                    <Redirect to="/" />
                ) : (
                    <Component {...props} {...rest} />
                )
            }
        />
    );
}