import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';
import 'firebase/firebase-database';

const config = require('./config.json');
const firebaseErrors = require('../../config/firebaseErrors.json');

class Firebase {
	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.emailProvider = new app.auth.EmailAuthProvider();
		this.db = app.firestore();
		this.realtimedatabase = app.database();
		this.functions = app.functions();
		this.storage = app.storage();

		app.auth().useDeviceLanguage();
	}

	doCreateUserWithEmailAndPassword = (data) => {
		//({ email, password, displayName, phoneNumber})=>{
		const createUser = this.functions.httpsCallable('createUser');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await createUser(data);

				if (res.data.errorInfo) {
					const { code } = res.data.errorInfo;
					if (firebaseErrors[code.toUpperCase()]) {
						reject(firebaseErrors[code.toUpperCase()]);
					} else {
						reject(
							'Hubo un error en la creación de éste usuario, por favor revisa los parámetros'
						);
					}
				} else {
					resolve(res.data);
				}
			} catch (e) {
				reject(e);
			}
		});
	};

	doUpdateUser = (data) => {
		const updateUser = this.functions.httpsCallable('updateUser');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await updateUser(data);

				if (res.data.errorInfo) {
					const { code } = res.data.errorInfo;
					if (firebaseErrors[code.toUpperCase()]) {
						reject(firebaseErrors[code.toUpperCase()]);
					} else {
						reject(
							'Hubo un error en la edición de éste usuario, por favor revisa los parámetros'
						);
					}
				} else {
					resolve(res.data);
				}
			} catch (e) {
				reject(e);
			}
		});
	};

	deleteUser = (uid) => {
		const deleteUser = this.functions.httpsCallable('deleteUser');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await deleteUser({ uid });

				resolve(res.data);
			} catch (e) {
				reject(e);
			}
		});
	};

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	updateUser = (data) => this.auth.currentUser.updateProfile(data);

	doSendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

	doSignOut = () => this.auth.signOut();

	set = (collection, id, data) =>
		this.db.collection(collection).doc(id).set(data);

	get = (collection, id) => {
		if (typeof id === 'object') {
			return this.db
				.collection(collection)
				.where(id.field, id.condition, id.value)
				.get();
		}

		return this.db.collection(collection).doc(id).get();
	};

	update = (collection, id, data) =>
		this.db.collection(collection).doc(id).update(data);

	delete = (collection, id) => this.db.collection(collection).doc(id).delete();

	getWithSocket = ({ collection, condition, callback }) => {
		if (condition) {
			return this.db
				.collection(collection)
				.where(condition.field, condition.condition, condition.value)
				.onSnapshot(callback);
		}
		return this.db.collection(collection).onSnapshot(callback);
	};

	uploadFile = (path, file, filename) => {
		filename = filename || file.name;
		const storageRef = this.storage.ref();

		storageRef.child(`${path}`);
		const imageRef = storageRef.child(`${path}/${filename}`);
		return new Promise((resolve, reject) => {
			imageRef
				.put(file)
				.then((response) => {
					imageRef
						.getDownloadURL()
						.then((url) => {
							resolve({ response, url });
						})
						.catch(reject);
				})
				.catch(reject);
		});
	};

	getRoom = (room, callback) => {
		const dbRef = this.realtimedatabase.ref(room);
		dbRef.on('value', callback);
	};
}

export default Firebase;
