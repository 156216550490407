import React from 'react';

import {
    Icon, 
    MenuContainer,
    Menu,
    MenuHeader,
    UserName,
    UserEmail,
    Item
} from './Styles';

const HeaderClass = ({ user, onLogout})=>{
    const [showMenu, setShowMenuStatus] = React.useState(false);

    const handleFocus = (e)=>{ 
        setShowMenuStatus(!showMenu);
    }
    
    const { email, displayName } = user;
    return (
        <MenuContainer onFocus={handleFocus} onBlur={handleFocus}>
                {showMenu && (
                    <Menu>
                        <MenuHeader>
                            <UserName>{displayName}</UserName>
                            <UserEmail>{email}</UserEmail>
                        </MenuHeader>
                        <Item>
                            <div onClick={onLogout}><i className="fas fa-sign-out-alt" /> Salir</div>
                        </Item>
                    </Menu>
                )}
                <Icon className="fas fa-user" />
        </MenuContainer>
    )
}

export default HeaderClass;

