import React, { Component } from "react"
import {
    BrowserRouter as Router, 
    Switch
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";


import { withFirebase } from '../components/Firebase';

import AsyncComponent from "./AsyncComponent";

import LoadingScreen from '../components/LoadingScreen';
import Header from '../components/Header';
import SideBar from '../components/SideBar';


const Login = AsyncComponent(() => import("../screens/Login"));
const ForgotPassword = AsyncComponent(() => import("../screens/ForgotPassword"));
const Companies = AsyncComponent(() => import("../screens/Companies"));
const CompaniesNew = AsyncComponent(() => import("../screens/Companies/New"));
const Users = AsyncComponent(() => import("../screens/Users"));
const NewUser = AsyncComponent(() => import("../screens/NewUser"));
const Downloads = AsyncComponent(() => import("../screens/Downloads"));
const HistoryConnections = AsyncComponent(() => import("../screens/HistoryConnections"));





class MyRouter extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading : true
         };

        this.onLogout = this.onLogout.bind(this);
    }
    componentWillMount() {
        const self = this;
        this.props.firebase.auth.onAuthStateChanged(user => {
            if (user) { 
                self.props.firebase.get('companies', user.uid)
                .then((doc)=>{
                    const _userData = doc.data();

                    self.setState({
                        isLoading: false,
                        authenticated: true,
                        currentUser: {...user, ..._userData},
                        userProfile: (doc.exists ? _userData.profile : 'user')
                    });
                });
            } else {
                this.setState({
                    isLoading : false,
                    authenticated: false,
                    currentUser: null
                });
            }
        });
        
    }
    onLogout() {
        this.props.firebase.doSignOut()
        .then(function () {
        }, function (error) {
            console.log(error);
        });
    }
    render(){
        const { authenticated = false, isLoading } = this.state;

        if (isLoading){
            return <LoadingScreen />
        }

        if (!authenticated){
            return (
                    <Router basename={process.env.PUBLIC_URL}>
                        <Switch>
                            <GuestRoute path="/forgot-password" exact component={ForgotPassword} />
                            <GuestRoute component={Login}/>
                        </Switch>
                    </Router>
            );

        } 

        const { currentUser = {}, userProfile = 'user' } = this.state;

        if (userProfile === 'user'){
            return (
            <Router basename={process.env.PUBLIC_URL}>
                <Header user={currentUser} onLogout={this.onLogout} />
                <Switch>
                    <PrivateRoute component={Downloads} profile={userProfile} authenticated={authenticated} />
                </Switch>
            </Router>
            )
        }

        return (
            <Router basename={process.env.PUBLIC_URL}>
                <Header user={currentUser} onLogout={this.onLogout} />
                <SideBar profile={userProfile}/>
                <Switch>
                    {(userProfile === 'superadmin' || userProfile === 'admin') && (
                        <PrivateRoute path="/companies/new" profile={userProfile} component={CompaniesNew} authenticated={authenticated} />
                    )}
                    {(userProfile === 'superadmin' || userProfile === 'admin') && (
                        <PrivateRoute path="/companies/edit/:cid" profile={userProfile} component={CompaniesNew} authenticated={authenticated} />
                    )}

                    <PrivateRoute path="/users/new" component={NewUser} profile={userProfile} currentUser={currentUser} authenticated={authenticated} />
                    <PrivateRoute path="/users/edit/:uid" component={NewUser} profile={userProfile} currentUser={currentUser} authenticated={authenticated} />
                    <PrivateRoute path="/users/history/:uid" component={HistoryConnections} profile={userProfile} authenticated={authenticated} />
                    <PrivateRoute path="/users" component={Users} profile={userProfile} authenticated={authenticated} />
                    <PrivateRoute path="/downloads" component={Downloads} profile={userProfile} authenticated={authenticated} />
                    

                    {(userProfile === 'superadmin' || userProfile === 'admin') && (
                        <PrivateRoute component={Companies} profile={userProfile} authenticated={authenticated}/>
                    )}

                    {(userProfile !== 'superadmin' && userProfile !== 'admin') && (
                        <PrivateRoute component={Users} profile={userProfile} authenticated={authenticated} />
                    )}
                </Switch>
            </Router>

        )
    }
};

export default withFirebase(MyRouter);