import styled from 'styled-components';

export const MenuContainer = styled.button`
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    outline : none;
    z-index: 10;
    &:focus{
        outline: none;
        border: none;
    }
`;

export const Icon = styled.i`
    border: 4px solid rgba(0, 0, 0, 0.14);
    border-radius: 50px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.34);
    cursor: pointer;
    font-size: 28px;
    position: absolute;
    right: 8px;
    padding: 8px;
    top: 12px;
    &:hover{
        background: rgba(0, 0, 0, 0.14);
        opacity: 0.8;
    }
    z-index: 1;
`;

export const Menu = styled.div`
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.24);
  margin-right: 3rem;
  margin-top: 3rem;
  width: 250px;
  z-index: 2;
  &>div{
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    padding: 8px 12px;
  }
`;

export const MenuHeader = styled.div`
  text-align: right;
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 1.1em;
`;

export const UserEmail = styled.div`
  color: var(--secondary);
  font-size: 11px;
`;

export const Item = styled.div`
  font-size: 14px;
  text-align: left;
  &:hover{
    background: var(--mediocomercial);
    color: white;
    cursor: pointer;
  }
`;
